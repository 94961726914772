import React from 'react';

export const ConfigPagesConfig = [
  {
    auth: ['superadmin','admin','ContentCreator'],
    routes: [
      {
        path: '/Config/overview/:page',
        component: React.lazy(() => import('./Pages/detail')),
      },
    ],
  },

];
