import axios from 'axios';
let apiRoot: string | undefined;

if (process.env.REACT_APP_MODE === 'LOCAL') {
  apiRoot = process.env.REACT_APP_API_URL_LOCAL;
} else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
  apiRoot = process.env.REACT_APP_API_URL_DEVELOPMENT;
} else if (process.env.REACT_APP_MODE === 'LIVE') {
  apiRoot = process.env.REACT_APP_API_URL_LIVE;
} else if (process.env.REACT_APP_MODE === 'KLIMABAUHELD') {
  apiRoot = process.env.REACT_APP_API_URL_KLIMABAUHELD;
}
const jwtAxios = axios.create({
  baseURL: apiRoot, //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});
jwtAxios.interceptors.response.use(
  res => res,
  err => {
    if (err.response && err.response.data.msg === 'Token is not valid') {
      console.log('Need to logout user');
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  },
);
export const setAuthToken = (token: string | null) => {
  localStorage.removeItem('token');
  if (token) {
    jwtAxios.defaults.headers.common['Authorization'] = "Bearer " +token;
    localStorage.setItem('token', token);
    //console.log(localStorage.getItem('token'));
  } else {
    delete jwtAxios.defaults.headers.common['Authorization'];
    localStorage.removeItem('token');
  }
};

export default jwtAxios;
