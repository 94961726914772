import React from 'react';

export const MemberMerchantPagesConfig = [
  {
    auth: ['superadmin','merchant'],
    routes: [
      {
        path: '/Member/Merchant/overview/:page',
        component: React.lazy(() => import('./Pages/overview')),
      },
    ],
  },
  {
    auth: ['merchant'],
    routes: [
      {
        path: '/Member/Merchant/Detail/:id',
        component: React.lazy(() => import('./Pages/Detail')),
      },
    ],
  },
  {
    auth: ['merchant'],
    routes: [
      {
        path: '/Member/Merchant/Delete/:page/:deleteID',
        component: React.lazy(() => import('./Pages/overview')),
      },
    ],
  },
  {
    auth: ['merchant'],
    routes: [
      {
        path: '/Member/Merchant/new/',
        component: React.lazy(() => import('./Pages/new')),
      },
    ],
  },
];
