import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppLayout from "@crema/core/AppLayout";
import AuthRoutes from "@crema/utility/AuthRoutes";
import LocaleProvider from "@crema/utility/LocaleProvider";
import CremaThemeProvider from "@crema/utility/CremaThemeProvider";
import CremaStyleProvider from "@crema/utility/CremaStyleProvider";
import ContextProvider from "@crema/utility/ContextProvider";

import CssBaseline from "@material-ui/core/CssBaseline";
import InfoViewContextProvider from "@crema/core/InfoView/InfoViewContext";
import ReactNotification from 'react-notifications-component'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications-component/dist/theme.css'
import MediaGalerie from "./@Pielers/components/const/mediaGalerie";

const App = () => (
  <div className="app-container">
  <ReactNotification />
  <ContextProvider>
    <InfoViewContextProvider>
        <CremaThemeProvider>
          <CremaStyleProvider>
            <LocaleProvider>
              <BrowserRouter >
                <AuthRoutes>
                  <CssBaseline />
                  <AppLayout />
                  <MediaGalerie />
                </AuthRoutes>
              </BrowserRouter>
            </LocaleProvider>
          </CremaStyleProvider>
        </CremaThemeProvider>
    </InfoViewContextProvider>
  </ContextProvider>
  </div>
);

export default App;
