import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import {Row} from 'react-bootstrap';
import {isEmptyArray} from 'formik';
import {number, ValidateMessage} from '../../components/const';
import deleteFetchButton from './helper/deleteFetch';
import deleteTaxonButton from './helper/deleteTaxon';
import legende from './helper/legende';
import IntlMessage, {IntlMessageValue} from '@Pielers/components/const/IntlMessage';
const holder = (data: any,i:any) => {

  let row = [];
  if(data.type === 'taxon'){
    for(let x = 0; x < data.ressource.length;x++) {
      row.push(<Row>
        <InputGroup className="mb-3 appendTaxon"  id={'taxon'+x}>
          <InputGroup.Text><IntlMessage messageId="common.category" /></InputGroup.Text>
          <FormControl name={'Artikelkategorie['+x+'][id]'} type={'hidden'} aria-label="id" value={data.ressource[x].id} />
          <FormControl name={'Artikelkategorie['+x+'][name]'} aria-label="name" value={data.ressource[x].name} readOnly={true} />
          {deleteFetchButton('taxon'+x)}
        </InputGroup>
      </Row>   )
    }
    return (
      <>
        <Row>
          <hr />
        <p><IntlMessage messageId="common.article_category" /></p>
        <br />
        {row}
        <div id={'taxon'}>
        </div>
          <hr />
        </Row>
      </>)
  } else if(data.type === 'taxonMerchant'){
    for(let x = 0; x < data.ressource.length;x++) {
      row.push(<Row>
        <InputGroup className="mb-3 appendTaxon"  id={'taxon'+x}>
          <InputGroup.Text><IntlMessage messageId="common.provider_category" /></InputGroup.Text>
          <FormControl name={'taxons['+x+'][id]'} className={'taxon'}  title={IntlMessageValue("provider.category.title")} type={'hidden'} aria-label="id" value={data.ressource[x].id} />
          <FormControl name={'taxons['+x+'][name]'} className={'taxon'} title={IntlMessageValue("provider.category.name")} type={'text'} aria-label="name" value={data.ressource[x].name} readOnly={true} />
          <FormControl name={'taxons['+x+'][order]'} className={'taxon'} title={IntlMessageValue("provider.category.order")} type={'text'} aria-label="name" value={data.ressource[x].order} readOnly={true} />
          {deleteTaxonButton('taxon'+x)}
        </InputGroup>
      </Row>   )
    }
    return (
      <>
        <Row>
          <hr />
          <p>Anbieterkategorien</p>
          <br />
          {row}
          <Row id={'taxon'}>
          </Row>
          <hr />
        </Row>
      </>)
  }else if (data.type === 'Shipping'){
    if(data.ressource !== undefined){
      data.legende = <IntlMessage messageId="shipping.price_format.legend" />;
      for(let x = 0; x < data.ressource.length;x++) {
        row.push(
          <Row id={'shippingprice'}>
            <InputGroup className="mb-3 appendTaxon"  id={'Shipping'+data.ressource[x].id}>
              <InputGroup.Text><IntlMessage messageId="common.method" /></InputGroup.Text>
              <FormControl name={'Shipping['+data.ressource[x].id+'][id]'} type={'hidden'} aria-label="id" value={data.ressource[x].id} />
              <FormControl name={'Shipping['+data.ressource[x].id+'][name]'} aria-label="name" value={data.ressource[x].name} readOnly={true} />
              <InputGroup.Text><IntlMessage messageId="common.shipping_price" /></InputGroup.Text>
              <FormControl id={'shippingpriceInput'} name={'Shipping['+data.ressource[x].id+'][price]'} type={'text'} aria-label="price" defaultValue={number('de',(data.ressource[x].price /100))} required={true}/> {/*data.ressource[x].price*/}
              <InputGroup.Text>€</InputGroup.Text>
              {deleteFetchButton('Shipping'+data.ressource[x].id)}
              <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} />
            </InputGroup>
            {legende(data)}
          </Row>   )
      }
      return (
        <>
          <Row>
            <hr />
            <p><IntlMessage messageId="common.deviating_shipping_information" /></p>
            <br />
            <div id={'shipping'}>
            {row}
            </div>
            <Row id={'Shipping'}>
            </Row>
            <hr />
          </Row>
        </>)
    }
    return (
      <>
        <Row>
          <hr />
          <p><IntlMessage messageId="common.deviating_shipping_information" /></p>
          <br />
          <Row id={'Shipping'}>
          </Row>
          <hr />
        </Row>
      </>)
  } else if (data.type === 'Shippingmerchant'){
    if(data.ressource !== undefined){
      let value;

      for(let x = 0; x < data.ressource.length;x++) {
        if(!isEmptyArray(data.value) && data.value !== undefined) {
          if(data.value[x] !== undefined) {
            value = data.value[x].price;
          }
        } else {
          value = 0;
        }
        row.push(
          <Row>
            <InputGroup className="mb-3 appendTaxon"  id={'Shippingmerchant'+data.ressource[x].id}>
              <InputGroup.Text>Versand</InputGroup.Text>
              <FormControl name={'MerchantShippingType['+x+'][id]'} type={'hidden'} aria-label="id" value={data.ressource[x].id} />
              <FormControl name={'MerchantShippingType['+x+'][name]'} type={'text'} value={data.ressource[x].name} readOnly={true}/>
              <FormControl name={'MerchantShippingType['+x+'][price]'} type={'text'}  defaultValue={number('de',(value /100))}/> {/*data.ressource[x].price*/}
            </InputGroup>
          </Row>   )
      }
      return (
        <>
          <Row>
            <hr />
            <p><IntlMessage messageId="common.shipping" /></p>
            <br />
            {row}
            <Row id={'Shipping'}>
            </Row>
            <hr />
          </Row>
        </>)
    }
    return (
      <>
        <Row>
          <hr />
          <p><IntlMessage messageId="common.shipping" /></p>
          <br />
          <Row id={'Shipping'}>
          </Row>
          <hr />
        </Row>
      </>)
  } else if(data.type === 'Products') {
    for (let x = 0; x < data.item.length; x++) {
    row.push(<Row>
      <InputGroup className="mb-3 append" id={data.appendid+'[Products]' + x}>
        <InputGroup.Text><IntlMessage messageId="common.product" /></InputGroup.Text>
        <FormControl name={data.appendid+'[Products][][id]'} type={'hidden'} aria-label="id" value={data.item[x].id} readOnly={true}/>
        <InputGroup.Text>Code:</InputGroup.Text>
        <FormControl name={data.appendid+'[Products][][code]'} type={'text'} aria-label="code" value={data.item[x].code} readOnly={true}/>
        <InputGroup.Text>Name:</InputGroup.Text>
        <FormControl name={data.appendid+'[Products][][name]'} aria-label="name" value={data.item[x].name} readOnly={true} />
        {deleteFetchButton(data.appendid+'[Products]' + x)}
      </InputGroup>
    </Row>)
    }
    return (
      <>
        <Row>
          <hr />
          <br />
          {row}
          <div id={data.cointainerid}>
          </div>
          <hr />
        </Row>
      </>)
  } else {
    for (let x = 0; x < data.item.length; x++) {
      row.push(<Row>
        <InputGroup className="mb-3 append" id={'duo' + x + '' + data.index}>
          <InputGroup.Text><IntlMessage messageId="common.product" /></InputGroup.Text>
          <FormControl name={'products[' + x + '][code]'} type={'hidden'} aria-label="code" value={data.item[x].code} />
          <FormControl name={'products[' + x + '][id]'} type={'hidden'} aria-label="id" value={data.item[x].id} />
          <FormControl name={'products[' + x + '][name]'} aria-label="name" value={data.item[x].name} readOnly={true} />
          {deleteFetchButton('duo' + x + '' + data.index)}
        </InputGroup>
      </Row>)
    }
    return (
      <>
        <Row>
          <hr />
        <br />
        {row}
        <div id={i}>
        </div>
          <hr />
        </Row>
      </>)
  }

}

export default holder;
