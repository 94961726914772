import input from './elements/input';
import textarea from './elements/textarea';
import formFile from './elements/formFile';
import select from './elements/select';
import checkbox from './elements/checkbox';
import WYSIWYG from './elements/WYSIWYG';
import header from './elements/header';
import Col from 'react-bootstrap/Col';
import {Row} from 'react-bootstrap';
import col_helper from './elements/helper/col_helper';
import divider from './elements/divider';
import React from 'react';
import description from './elements/description';
import button from './elements/button';
import br from './elements/br';
import image from './elements/image';
import Fetchselect from './elements/Fetchselect';
import holder from './elements/holder';
import formFileBucket from './elements/formFileBucket';
import radio from './elements/radio';
import {IntlMessageValue} from '../components/const/IntlMessage';
import url from './elements/url';
import imageMerchant from './elements/imageMerchant';


function renderFormSwitch(data: any, i: number,optionalFuncFirst:any = false,optionalFuncSecond:any = false) {

  renderLanguage(data[i]);

    switch (data[i].formType) {
      case 'input':
        return input(data[i], i,optionalFuncFirst)
      case 'textarea':
        return textarea(data[i],i,optionalFuncSecond)
      case 'formFile':
        return formFile(data[i])
      case 'formFileBucket':
        return formFileBucket(data[i])
      case 'select':
        return select(data[i],i)
      case 'fetchselect':
        return Fetchselect(data[i]);
      case 'checkbox':
        return checkbox(data[i])
      case 'radio':
        return radio(data[i])
      case 'suneditor':
        return WYSIWYG(data[i], i, undefined)
      case 'header':
        return header(data[i])
      case 'image':
        return image(data[i])
      case 'imageMerchant':
        return imageMerchant(data[i])
      case 'description':
        return description(data[i])
      case 'button':
        return button(data[i])
      case 'two_input':
        return <><Col>{input(data[i].input[0], i)}</Col><Col>{input(data[i].input[1], i)}</Col></>
      case 'multiply':
        return <><Row>{col_helper(data[i])}</Row></>
      case 'multiplyinRow':
        return <>{col_helper(data[i])}</>
      case 'divider':
        return <>{divider()}</>
      case 'br':
        return <>{br()}</>
      case 'holder':
        return <>{holder(data[i], i)}</>
      case 'url':
        return url(data[i], i,optionalFuncFirst)
    }

}

export default renderFormSwitch;

/**
 * Helper für den FormBuilder um Language abzufangen und auszutauschen
 * @param {object} data beinhaltet Daten des Feldes
 */
const renderLanguage = (data:any) => {


  data.placeholder = helperLang(data.placeholder);

  data.value = helperLang(data.value);

  data.label = helperLang(data.label);

  return data;
}

const helperLang = (Key:any) => {
  let langKey:string[];
  let LangKyValue: string;
  let LangValue:string;
  if(typeof Key === 'string' && Key !== undefined) {
    if((Key).includes('lang')) {
    langKey = Key.split('lang[');
    if(langKey[1] !== undefined ) {
      LangKyValue = langKey[1].split(']')[0]
    }
    LangValue = IntlMessageValue(LangKyValue);
    } else {
      return Key;
    }
    return LangValue;
  } else {
    return Key;
  }


}