import AppLocale from '../../../shared/localization';
import React from 'react';
import PreperatorReplace from './preperator/replace';
import OwnerName from './preperator/owner_name';
import replaceHTMLTag from './replaceHTMLTag';

/**
 * @typedef IntlProps
 * @prop {string} messageId ID des Language Key
 * @prop {string} preperator Preperator Function name
 * @prop {string} param Param for Preperator Function
 * @prop {string} replace Replace value for Preperator
 * @author Marcel Ugurcu
 * @version 0.0.1
 *
 */
interface IntlProps {
  messageId: string,
  preperator?: string,
  param?: string,
  replace?: string | Element | number,
  htmlReplace?:boolean,
}

/**
 *
 * @param {IntlProps} props Übergabe Werte für Preperatoren, Language id
 * @constructor
 * @returns messageValue give part of now Locale messageValue back and before use any preperator for customize  as JSX Element
 * @author Marcel Ugurcu
 * @version 0.0.1
 * Example for Configuration
 * @example
 * <IntlMessage messageId={'blog.new.post'} />
 *
 */
const IntlMessage: React.FC<IntlProps> = (props) => {

  /**
   * locale wird aus der localStorage geholt
   */
  let locale = (localStorage.getItem('locale') !== undefined && localStorage.getItem('locale') !== null  ? localStorage.getItem('locale').toLocaleLowerCase() : 'de');

  /**
   * Language Datei wird in languageFile gepackt
   */
  let languageFile;
  if(AppLocale[locale] !== undefined) {
    languageFile = AppLocale[locale]?.messages;

  /**
   *  Falls vorhanden wird der Language string entnommen.
   */

  let messageValue = languageFile[props.messageId]



  /**
   *
   * Langvalue wurde nicht gefunden.
   * Es wird der übergebene Langkey zurück gegeben
   */
  if(messageValue === undefined) {
    messageValue = props.messageId
  }

  /**
   * Preperatoren werden durchlaufen falls einer angegeben wurde.
   *
   */
  switch (props.preperator) {
    case 'replace':
      let searchAndReplace = new PreperatorReplace(messageValue,props.param,props.replace)
      messageValue = searchAndReplace.Init()
      break;
    case 'owner':
      let preperatorOwnerName = new OwnerName(messageValue)
      messageValue = preperatorOwnerName.Init();
      break;
  }

   if(props.htmlReplace !== undefined && props.htmlReplace === true) {
      messageValue = replaceHTMLTag(messageValue);
    }


  /**
   * Ruckgabe der Langvalue
   */
  return(messageValue);
  } else {
    return (<></>);
  }
}

export default IntlMessage;


/**
 *
 * @param messageId
 * @param preperator
 * @param param
 * @param replace
 * @param bold
 * @constructor
 * @returns messageValue give part of now Locale messageValue back and before use any preperator for customize as String
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const IntlMessageValue = (messageId: string, preperator: string = '', param: string = '',replace: string | number | Element = '',htmlReplace?:boolean) => {
  /**
   * locale wird aus der localStorage geholt
   */
  let locale = (localStorage.getItem('locale') !== undefined && localStorage.getItem('locale') !== null  ? localStorage.getItem('locale').toLocaleLowerCase() : 'de');

  /**
   * Language Datei wird in languageFile gepackt
   */
  let languageFile;
  if(AppLocale[locale] !== undefined) {
    languageFile = AppLocale[locale]?.messages;

  /**
   *  Falls vorhanden wird der Language string entnommen.
   */
  let messageValue = languageFile[messageId]



  /**
   *
   * Langvalue wurde nicht gefunden.
   * Es wird der übergebene Langkey zurück gegeben
   */
  if(messageValue === undefined) {
    messageValue = messageId
  }

  /**
   * Preperatoren werden durchlaufen falls einer angegeben wurde.
   *
   */
  switch (preperator) {
    case 'replace':
      let searchAndReplace = new PreperatorReplace(messageValue, param, replace)
      messageValue = searchAndReplace.Init()
      break;
    case 'owner':
      let preperatorOwnerName = new OwnerName(messageValue)
      messageValue = preperatorOwnerName.Init();
      break;
  }

    if(htmlReplace !== undefined && htmlReplace === true) {
      messageValue = replaceHTMLTag(messageValue);
    }

  /**
   * Ruckgabe der Langvalue
   */
  return(messageValue);
  } else {
    return (<></>);
  }
};
