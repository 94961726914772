import React from 'react';

export const TicketPagesConfig = [
  {
    auth: ['superadmin','admin'],
    routes: [
      {
        path: '/ticket/overview/:page',
        component: React.lazy(() => import('./Pages/Overview')),
      },
    ],
  },{
    auth: ['superadmin','admin'],
    routes: [
      {
        path: '/ticket/Issue/:id',
        component: React.lazy(() => import('./Pages/issue')),
      },
    ],
  },{
    auth: ['superadmin','admin'],
    routes: [
      {
        path: '/ticket/YTIssue/:id',
        component: React.lazy(() => import('./Pages/YTIssueFrame')),
      },
    ],
  },{
    auth: ['superadmin','admin'],
    routes: [
      {
        path: '/ticket/edit/Issue/:id',
        component: React.lazy(() => import('./Pages/editIssue')),
      },
    ],
  },{
    auth: ['superadmin','admin'],
    routes: [
      {
        path: '/ticket/new/Issue',
        component: React.lazy(() => import('./Pages/newIssue')),
      },
    ],
  },
  {
    auth: ['superadmin','admin'],
    routes: [
      {
        path: '/ticket/Label/overview',
        component: React.lazy(() => import('./Pages/LabelEdit')),
      },
    ],
  },
];