import React from 'react';

export const merchantPagesConfig = [
  {
    auth: ['superadmin','admin','operator'],
    routes: [
      {
        path: '/merchant/overview/:page',
        component: React.lazy(() => import('./Pages/overview')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','operator','merchant','merchant_ma','haendler','hersteller','handwerker','architekt'],
    routes: [
      {
        path: '/merchant/Detail/:id',
        component: React.lazy(() => import('./Pages/Detail')),
      },
    ],
  },
  {
    auth: ['merchant','merchant_ma','haendler','hersteller','handwerker','architekt'],
    routes: [
      {
        path: '/merchant/Dashboard',
        component: React.lazy(() => import('./Pages/dashboard')),
      },
    ],
  },
];
