import React from 'react';

export const OrdersPagesConfig = [
  {
    auth: ['superadmin','admin','operator'],
    routes: [
      {
        path: '/Orders/overview/:page',
        component: React.lazy(() => import('./Pages/Ordersoverview')),
      },
    ],
  },
  {
    auth: ['merchant','merchant_ma'],
    routes: [
      {
        path: '/Orders/Merchant/overview/:page',
        component: React.lazy(() => import('./Pages/OrdersMerchantoverview')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','operator'],
    routes: [
      {
        path: '/Orders/Detail/:id',
        component: React.lazy(() => import('./Pages/OrdersDetail')),
      },
    ],
  },
  {
    auth: ['merchant','merchant_ma'],
    routes: [
      {
        path: '/Orders/Merchant/Detail/:id',
        component: React.lazy(() => import('./Pages/OrdersMerchantDetail')),
      },
    ],
  },
];
