import { useContext, useEffect, useState } from "react";
import { AuthType } from "../../shared/constants/AppEnums";
import jwtAxios from "../services/ApiConfig";
import { AuthUser } from "../../types/models/AuthUser";
import { fetchStart, fetchSuccess, useInfoViewActionsContext } from "../core/InfoView/InfoViewContext";
import AppContext from "./AppContext";
import AppContextPropsType from "../../types/AppContextPropsType";
import {error} from '../../@Pielers/components/function/error.services';

export const useAuthToken = (): [boolean, AuthUser | null] => {
  const [loading, setLoading] = useState(true);
  const {user, updateAuthUser} = useContext<AppContextPropsType>(AppContext);
  const dispatch = useInfoViewActionsContext()!;

  useEffect(() => {
    const validateAuth = async () => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      if (!token) {
        dispatch(fetchSuccess());
        return;
      }

      jwtAxios.defaults.headers.common['Authorization'] = "Bearer " + token;
      try {
        const res = await jwtAxios.get('/token/user/')
        dispatch(fetchSuccess());
        localStorage.setItem('locale',(res.data.locale_code === 'en_EN' ? 'en' : (res.data.locale_code === 'de_DE' ? 'de' : 'de')))
        updateAuthUser({
            authType: AuthType.JWT_AUTH,
            displayName: res.data.username,
            email: res.data.email,
            role: res.data.roles,
            token: token,
            uid: res.data.id,
            is_superuser: res.data.is_superuser,
            photoURL:  "",
            merchantID: res.data.merchant_id,
          });
        return;
      } catch (err) {
        error(err);
        dispatch(fetchSuccess());
        return;
      }
    };

    const checkAuth = () => {
      Promise.all([validateAuth()]).then(() => {
        setLoading(false);
      });
    };
    checkAuth();
  }, [dispatch,updateAuthUser]);

  return [loading, user];
};

export const useAuthUser = (): AuthUser | null => {
  const {user} = useContext<AppContextPropsType>(AppContext);
  if (user) {
    return user;
  }
  return null;
};
