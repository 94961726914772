import React from 'react';
import Form from 'react-bootstrap/Form';
import legende from './helper/legende';
import Col from "react-bootstrap/Col";
import Gallery from '../../components/function/galery';
import IntlMessage from "../../components/const/IntlMessage";

function formFile(data: any) {
  const namespace = 'galerieFile'+data.expandfile

  let label =(data.label !== undefined ?(<>  <Form.Label>{data.label}</Form.Label></>) : (<></>))
  let br = (data.Nonebr === 'true' ? (<></>) : (<><br /></>))
  if(data.experiment !== undefined && process.env.REACT_APP_MEDIA_GALERIE === 'True') {

    const senden = () => {

      let eles =  document.getElementById('dialog') as HTMLDivElement;
      eles.style.display = 'block';
      if(data.jsonID !== null && data.jsonID !== undefined ) {
        Gallery(data.append,data.src,namespace,data.mode,data.jsonID,data.appendJson,data.appendJsonField)
      } else {
        Gallery(data.append,data.src,namespace,data.mode)
      }


    }


    return(
      <>
        {label}
        {br}
        <div className="mb-3" style={{
          border:' 1px solid #ced4da',
          cursor: 'pointer',
          borderRadius: '0.25rem',
          transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
        }}  onClick={() =>senden()}>

          <Col
            style={{minWidth:'155px',maxWidth:'155px',padding:0,float:'left',marginRight:'10px'}}
          >
            <span className="input-group-text" style={{cursor: 'pointer'}}><IntlMessage  messageId="common.formfile.file" /></span>
          </Col>
          <Col
            style={{minWidth:'125px'}}
          >
            <label className="form-label" style={{paddingTop: '8px',cursor: 'pointer'}} id={namespace}><IntlMessage  messageId="common.formfile.none_selected" /></label>
          </Col>
        </div>
        <p style={{color: '#b0b0b0', fontSize: '14px', lineHeight: '1.3em', marginTop: '5px'}} key={'legende'}>
          <IntlMessage messageId="bucket.file.placeholder" />
        </p>

      </>
    )
  } else {

    return (
      <>
        {br}
        <Form.Group controlId={"formFile" + data.label} key={"formFile" + data.label} className="mb-3">
          <Form.Label>{data.label}</Form.Label>
          <Form.Control type={data.type}/>
        </Form.Group>
        {legende(data)}
      </>
    );

  }
}

export default formFile;
