import React from 'react';

export const SettingsMerchantPagesConfig = [
  {
    auth: ['merchant', 'merchant_ma'],
    routes: [
      {
        path: '/SettingsMerchant/data',
        component: React.lazy(() => import('./Pages/SettingsData')),
      },
    ],
  },
];
