import React from 'react';

export const MonitoringPagesConfig = [
  {
    auth: ['superadmin','admin'],
    routes: [
      {
        path: '/Monitoring/overview/:page',
        component: React.lazy(() => import('./Pages/overview')),
      },
    ],
  },
  {
    auth: ['superadmin','admin'],
    routes: [
      {
        path: '/Monitoring/API/overview/:page',
        component: React.lazy(() => import('./Pages/MonitoringSystemAPIOverview')),
      },
    ],
  },
  {
    auth: ['superadmin','admin'],
    routes: [
      {
        path: '/Monitoring/email/overview/:page',
        component: React.lazy(() => import('./Pages/MonitoringSystemAPIOutbound')),
      },
    ],
  },
  {
    auth: ['superadmin','admin'],
    routes: [
      {
        path: '/Monitoring/email/detail/:id',
        component: React.lazy(() => import('./Pages/Emailcontent')),
      },
    ],
  },
];
