import React from 'react';

export const SnippetPagesConfig = [
  {
    auth: ['superadmin','admin','ContentCreator'],
    routes: [
      {
        path: '/Snippet/overview/:page',
        component: React.lazy(() => import('./Pages/Snippetoverview')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','ContentCreator'],
    routes: [
      {
        path: '/Snippet/Detail/:id',
        component: React.lazy(() => import('./Pages/SnippetDetail')),
      },
    ],
  },

  {
    auth: ['superadmin','admin','ContentCreator'],
    routes: [
      {
        path: '/Snippet/Delete/:page/:deleteID',
        component: React.lazy(() => import('./Pages/Snippetoverview')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/Snippet/new',
        component: React.lazy(() => import('./Pages/new')),
      },
    ],
  },
];
