import React from 'react';
import {Redirect} from 'react-router-dom';

import {createRoutes} from '../@crema/utility/Utils';
import {samplePagesConfig} from './sample';
import {notificationPagesConfig} from './notification/index';
import {chatPagesConfig} from './chat/index';
import {merchantPagesConfig} from '../@Pielers/merchant/modules/merchant';
import {errorPagesConfigs} from './errorPages';
import {authRouteConfig} from './auth';
import {PagePagesConfig} from '../@Pielers/content/modules/Pages';
import {SnippetPagesConfig} from '../@Pielers/content/modules/Snippet';
import {BlogPagesConfig} from '../@Pielers/content/modules/Blog';
import {ProductPagesConfig} from '../@Pielers/products/modules/Product';
import {EmailPagesConfig} from '../@Pielers/content/modules/Email';
import {OrdersPagesConfig} from '../@Pielers/order/modules/Orders';
import {SettingsPagesConfig} from '../@Pielers/settings/modules/Settings';
import {TaxonPagesConfig} from '../@Pielers/settings/modules/Taxon';
import {TaxPagesConfig} from '../@Pielers/settings/modules/Tax';
import {ShippingPagesConfig} from '../@Pielers/settings/modules/Shipping';
import {
  chatPagesUrl,
  errorPageUrl,
  initialNotificationUrl,
  PageDeletePagesUrl,
  PageDetailPagesUrl,
  MerchantPagesUrl,
  PagePagesUrl,
  MerchantDetailPagesUrl,
  SnippetPagesUrl,
  SnippetDetailPagesUrl,
  BlogPagesUrl,
  BlogDetailPagesUrl,
  ProductPagesUrl,
  EmailPagesUrl,
  EmailDetailPagesUrl,
  OrdersPagesUrl,
  OrdersDetailPagesUrl,
  ProductDetailPagesUrl,
  SettingsDataPagesUrl,
  SettingsPreHeaderPagesUrl,
  BlogNewPagesUrl,
  PageNewPagesUrl,
  SnippetNewPagesUrl,
  EmailNewPagesUrl,
  BlogDeletePagesUrl,
  SnippetDeletePagesUrl,
  ProductNewPagesUrl,
  TaxonPagesUrl,
  TaxPagesUrl,
  MemberPagesUrl,
  SettingsDataMerchantPagesUrl,
  MemberMerchantPagesUrl,
  initialUrlChannel,
  initialMerchantUrl,
  ShippingPagesUrl,
  MonitoringPagesUrl,
  InvoicePagesUrl,
  TicketPagesUrl,
  CalenderPagesUrl,
  TextBlocksPagesUrl,
  CustomerPagesUrl,
  PromotionPagesUrl,
  PreloadPagesUrl,
  MatomoPagesUrl,
  customizerPagesUrl,
  ConfigPagesUrl
} from '../shared/constants/AppConst';
import {MemberPagesConfig} from '../@Pielers/settings/modules/Member';
import {SettingsMerchantPagesConfig} from '../@Pielers/settings/modules/SettingsMerchant';
import {MemberMerchantPagesConfig} from '../@Pielers/settings/modules/MemberMerchant';
import {MonitoringPagesConfig} from '../@Pielers/monitoring/modules/monitoring';
import {UnitPagesConfig} from '../@Pielers/settings/modules/Unit';
import {InvoicePagesConfig} from '../@Pielers/invoice/modules/invoice';
import {TicketPagesConfig} from '../@Pielers/ticketsystem/modules';
import {CalenderPagesConfig} from '../@Pielers/content/modules/calender';
import {TextBlocksPagesConfig} from '../@Pielers/settings/modules/TextBlocks';
import {CustomerPagesConfig} from '../@Pielers/settings/modules/Customer';
import {PromotionPagesConfig} from '../@Pielers/order/modules/Promotion';
import {PreloaderPagesConfig} from '../@Pielers/preloaderPielers/modules';
import {MatomoPagesConfig} from '../@Pielers/matomo/modules';
import {customizerPagesConfig} from '../@Pielers/content/modules/customize/modules';
import {ConfigPagesConfig} from '../@Pielers/settings/modules/config';


const routeConfigs = [
  ...samplePagesConfig,
  ...errorPagesConfigs,
  ...authRouteConfig,
  ...notificationPagesConfig,
  ...chatPagesConfig,
  ...PagePagesConfig,
  ...merchantPagesConfig,
  ...SnippetPagesConfig,
  ...BlogPagesConfig,
  ...ProductPagesConfig,
  ...EmailPagesConfig,
  ...OrdersPagesConfig,
  ...SettingsPagesConfig,
  ...TaxonPagesConfig,
  ...TaxPagesConfig,
  ...MemberPagesConfig,
  ...SettingsMerchantPagesConfig,
  ...MemberMerchantPagesConfig,
  ...ShippingPagesConfig,
  ...UnitPagesConfig,
  ...MonitoringPagesConfig,
  ...InvoicePagesConfig,
  ...TicketPagesConfig,
  ...CalenderPagesConfig,
  ...TextBlocksPagesConfig,
  ...CustomerPagesConfig,
  ...PromotionPagesConfig,
  ...PreloaderPagesConfig,
  ...MatomoPagesConfig,
  ...customizerPagesConfig,
  ...ConfigPagesConfig
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    auth: ['superadmin','admin','operator','ContentCreator','Author'],
    exact: true,
    component: () => <Redirect to={initialUrlChannel} />,
  },
    {
      path: '/Preload/',
      exact: true,
      auth: ['superadmin','admin','operator','ContentCreator','Author'],
      component: () => <Redirect to={PreloadPagesUrl} />,
    },
  {
    path: '/',
    exact: true,
    auth: ['merchant','merchant_ma','operator'],
    component: () => <Redirect to={initialMerchantUrl} />,
  },
  {
    path: '/notification/overview',
    exact: true,
    component: () => <Redirect to={initialNotificationUrl} />,
  },
  {
    path: '/chat/messenger',
    exact: true,
    component: () => <Redirect to={chatPagesUrl} />,
  },
  {
    path: '/Pages/overview/:page',
    exact: true,
    component: () => <Redirect to={PagePagesUrl} />,
  },
  {
    path: '/Pages/Detail/:id',
    exact: true,
    component: () => <Redirect to={PageDetailPagesUrl}/>,
  },
  {
    path: '/Pages/new/',
    exact: true,
    component: () => <Redirect to={PageNewPagesUrl}/>,
  },
  {
    path: '/Pages/Delete/:page/:deleteID',
    exact: true,
    component: () => <Redirect to={PageDeletePagesUrl}/>,
  },
    {
      path: '/analytics/Dashboard/:page',
      exact: true,
      component: () => <Redirect to={MatomoPagesUrl}/>,
    },
  {
    path: '/merchant/overview/:page',
    exact: true,
    component: () => <Redirect to={MerchantPagesUrl} />,
  },
  {
    path: '/merchant/Detail/:id',
    exact: true,
    component: () => <Redirect to={MerchantDetailPagesUrl} />,
  },
  {
    path: '/Snippet/overview/:page',
    exact: true,
    component: () => <Redirect to={SnippetPagesUrl} />,
  },
  {
    path: '/Snippet/Detail/:id',
    exact: true,
    component: () => <Redirect to={SnippetDetailPagesUrl}/>,
  },

  {
    path: '/Snippet/Delete/:page/:deleteID',
    exact: true,
    component: () => <Redirect to={SnippetDeletePagesUrl}/>,
  },
  {
    path: '/Snippet/new',
    exact: true,
    component: () => <Redirect to={SnippetNewPagesUrl}/>,
  },
  {
    path: '/Blog/overview/:page',
    exact: true,
    component: () => <Redirect to={BlogPagesUrl} />,
  },
  {
    path: '/Blog/Detail/:id',
    exact: true,
    component: () => <Redirect to={BlogDetailPagesUrl}/>,
  },
  {
    path: '/Blog/new/',
    exact: true,
    component: () => <Redirect to={BlogNewPagesUrl}/>,
  },
  {
    path: '/Blog/Delete/:page/:deleteID',
    exact: true,
    component: () => <Redirect to={BlogDeletePagesUrl}/>,
  },
  {
    path: '/Product/overview/:page',
    exact: true,
    component: () => <Redirect to={ProductPagesUrl}/>,
  },
  {
    path: '/Product/Detail/:id',
    exact: true,
    component: () => <Redirect to={ProductDetailPagesUrl}/>,
  },

  {
    path: '/Product/new/',
    exact: true,
    component: () => <Redirect to={ProductNewPagesUrl}/>,
  },
  {
    path: '/Email/overview/:page',
    exact: true,
    component: () => <Redirect to={EmailPagesUrl}/>,
  },
  {
    path: '/Email/Delete/:page/:deleteID',
    exact: true,
    component: () => <Redirect to={EmailDetailPagesUrl}/>,
  },
  {
    path: '/Email/new',
    exact: true,
    component: () => <Redirect to={EmailNewPagesUrl}/>,
  },
  {
    path: '/Orders/overview/:page',
    exact: true,
    //auth: ['superadmin','admin','operator'],
    component: () => <Redirect to={OrdersPagesUrl}/>,
  },
  {
    path: '/Orders/Detail/:id',
    exact: true,
    component: () => <Redirect to={OrdersDetailPagesUrl}/>,
  },
  {
    path: '/Settings/data',
    exact: true,
    component: () => <Redirect to={SettingsDataPagesUrl}/>,
  },
  {
    path: '/Settings/preheader',
    exact: true,
    component: () => <Redirect to={SettingsPreHeaderPagesUrl}/>,
  },
  {
    path: '/Taxon/overview/:page',
    exact: true,
    component: () => <Redirect to={TaxonPagesUrl}/>,
  },
  {
    path: '/Tax/overview/:page',
    exact: true,
    component: () => <Redirect to={TaxPagesUrl}/>,
  },

  {
    path: '/Shipping/overview/:page',
    exact: true,
    component: () => <Redirect to={ShippingPagesUrl}/>,
  },
  {
    path: '/Member/overview/:page',
    exact: true,
    component: () => <Redirect to={MemberPagesUrl}/>,
  },
  {
    path: '/Member/Merchant/overview/:page',
    exact: true,
    component: () => <Redirect to={MemberMerchantPagesUrl}/>,
  },
  {
    path: '/SettingsMerchant/data',
    exact: true,
    component: () => <Redirect to={SettingsDataMerchantPagesUrl}/>,
  },
  {
    path: '/Monitoring/overview/:page',
    exact: true,
    component: () => <Redirect to={MonitoringPagesUrl}/>,
  },
  {
    path: '/Invoice/overview/:page',
    exact: true,
    component: () => <Redirect to={InvoicePagesUrl}/>,
  },
  {
    path: '/ticket/overview/:page',
    exact: true,
    component: () => <Redirect to={TicketPagesUrl}/>,
  },
  {
    path: '/calender/overview/:page',
    exact: true,
    component: () => <Redirect to={CalenderPagesUrl}/>,
  },
  {
    path: '/TextBlocks/overview/:page',
    exact: true,
    component: () => <Redirect to={TextBlocksPagesUrl}/>,
  },
    {
      path: '/Customeroverview/:page',
      exact: true,
      component: () => <Redirect to={CustomerPagesUrl}/>,
    },
      {
        path: '/Promotion/overview/:page',
        exact: true,
        component: () => <Redirect to={PromotionPagesUrl}/>,
      },
        {
          path: '/customizer/overview/:page',
          exact: true,
          component: () => <Redirect to={customizerPagesUrl}/>,
        },
        {
          path: '/Config/overview/:page',
          exact: true,
          component: () => <Redirect to={ConfigPagesUrl}/>,
        },
  {
    component: () => <Redirect to={errorPageUrl}  />,
  },

];

export default routes;
