import IntlMessage, {IntlMessageValue} from '@Pielers/components/const/IntlMessage';
import React from 'react';
import Form from 'react-bootstrap/Form';
import legende from './helper/legende';
import {ValidateMessage} from '../../components/const';

function textarea(data: any,i: number,optionalFuncSecond:any = false) {



  let required = false;
  if (data.required !== undefined && data.required !== null) {
    required = true;
  }

  const handleKeyPress = (e:any) => {
    if(e.key === 'Enter') {
      e.preventDefault()
      alert(<IntlMessage messageId="notification.enter_forbidden"/>)
    }
  }
  const handlefunc  = (e:any) => {
    optionalFuncSecond(e);
  }
  let maxContent = (data.max !== undefined ? data.max : 250)


  const setFormattedContent = (value:any,max:any) => {


    let chars = max - value.length ;

    document.getElementById('content'+i+data.label).innerHTML = IntlMessageValue("common.remaining_chars","replace","[n]",chars);
    if(data.seo !== undefined) {
      handlefunc(value)
    }
  }
  let InitialChars = maxContent - (data.value === null || data.value === undefined ? 0 : data.value.length) ;

  if(data.readonly !== null && data.readonly !== undefined){
    return (
      <>
        <Form.Group className="mb-3" controlId={"Form.ControlTextarea" + data.label}
                    key={"Form.ControlTextarea" + data.label}>
          <Form.Label>{data.label}</Form.Label>
          <Form.Control disabled={true} name={data.name} as={data.type} rows={data.row} maxLength={maxContent} defaultValue={data.value} required={required} onChange={event => setFormattedContent(event.target.value,maxContent)}/>
          <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} />
          <p id={'content'+i+data.label}>
            <IntlMessage messageId="common.remaining_chars" preperator="replace" param="[n]" replace={InitialChars} />
          </p>
        </Form.Group>
        {legende(data)}
      </>
    );
  }else if (data.forbidden === true) {
    return (
      <>
        <Form.Group className="mb-3" controlId={"Form.ControlTextarea"+i+data.label}
                    key={"Form.ControlTextarea" + data.label}>
          <Form.Label>{data.label}</Form.Label>
          <Form.Control  name={data.name} as={data.type} rows={data.row} maxLength={maxContent} defaultValue={data.value} required={required} onKeyDown={(e) => handleKeyPress(e)} onChange={event => setFormattedContent(event.target.value,maxContent)}/>
          <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} />
        <p id={'content'+i+data.label}>
        <IntlMessage messageId="common.remaining_chars" preperator="replace" param="[n]" replace={InitialChars} />
        </p>
        </Form.Group>
        {legende(data)}
      </>
    );
  } else {

    return (
      <>
        <Form.Group className="mb-3" controlId={"Form.ControlTextarea" + data.label}
                    key={"Form.ControlTextarea" + data.label}>
          <Form.Label>{data.label}</Form.Label>
          <Form.Control name={data.name} as={data.type} rows={data.row} maxLength={maxContent} defaultValue={data.value} required={required} onChange={event => setFormattedContent(event.target.value,maxContent)}/>
          <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} />
          <p id={'content'+i+data.label}>
            <IntlMessage messageId="common.remaining_chars" preperator="replace" param="[n]" replace={InitialChars} />
          </p>
        </Form.Group>
        {legende(data)}
      </>
    );
  }
}

export default textarea;

