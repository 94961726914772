import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import React from 'react';
import {SunEditorConfig} from '../../components/const';

function WYSIWYG(data: any, i: number, indi: any = undefined) {

  let apiRoot: string | undefined;

  if (process.env.REACT_APP_MODE === 'LOCAL') {
    apiRoot = process.env.REACT_APP_API_URL_LOCAL;
  } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
    apiRoot = process.env.REACT_APP_API_URL_DEVELOPMENT;
  } else if (process.env.REACT_APP_MODE === 'LIVE') {
    apiRoot = process.env.REACT_APP_API_URL_LIVE;
  } else if (process.env.REACT_APP_MODE === 'KLIMABAUHELD') {
    apiRoot = process.env.REACT_APP_API_URL_KLIMABAUHELD;
  }

  let value;
  if(data.multiValue !== undefined ) {
    if(data.multiValue.element !== undefined) {
      value = data.multiValue.element[data.multiValue.key];
    } else {
      value = '';
    }
  } else {
    value = data.value;
  }

  if (data.data !== undefined && data.hasOwnProperty('data')) {
    if (data.data.hasOwnProperty('lang')) {
      return (
        <div>
          <br />
          <label>{data.label}</label>
          <SunEditor name={data.name} lang={data.data.lang} defaultValue={value} key={'Sun' + i}  height={data.data.height}
                     setOptions={{
                       "imageWidth": "150px",
                       "imageHeight": "150px",
                       "imageGalleryUrl": apiRoot+"/MediaSunEditor/",
                       "imageGalleryHeader": {
                       "Authorization": "Bearer "+localStorage.getItem('token')
                     },
                       buttonList: (data.data.config !== undefined ? data.data.config : SunEditorConfig()),
                       attributesWhitelist:
                     {
                       div: 'id',
                       p: 'id',
                       h: 'id'
                     }
                     }} />
        </div>
      )
  }else {
      return (
        <div>
          <br />
          <label>{data.label}</label>
          <SunEditor name={data.name} defaultValue={value} key={'Sun' + i}
                     setOptions={{
                       "imageWidth": "150px",
                       "imageHeight": "150px",
                       "imageGalleryUrl": apiRoot+"/MediaSunEditor/",
                       "imageGalleryHeader": {
                         "Authorization": "Bearer "+localStorage.getItem('token')
                       },
                       buttonList: (data.data.config !== undefined ? data.data.config : SunEditorConfig()),
                       attributesWhitelist:
                         {
                           div: 'id',
                           p: 'id',
                           h: 'id'
                         }
                     }} />
        </div>
      )
    }} else {
    return (
      <div>
        <br />
        <label>{data.label}</label>
        <SunEditor name={data.name} defaultValue={value} key={'Sun' + i}
                   setOptions={{
                     "imageWidth": "150px",
                     "imageHeight": "150px",
                     "imageGalleryUrl": apiRoot+"/MediaSunEditor/",
                     "imageGalleryHeader": {
                       "Authorization": "Bearer "+localStorage.getItem('token')
                     },
                     buttonList: SunEditorConfig(),
                     attributesWhitelist:
                       {
                         div: 'id',
                         p: 'id',
                         h: 'id'
                       }
                   }} />
      </div>
    )
  }

}

export default WYSIWYG;
