import React from 'react';

export const PromotionPagesConfig = [
  {
    auth: ['superadmin','admin','operator'],
    routes: [
      {
        path: '/Promotion/overview/:page',
        component: React.lazy(() => import('./Pages/Promotionoverview')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','operator'],
    routes: [
      {
        path: '/Promotion/Detail/:id',
        component: React.lazy(() => import('./Pages/PromotionDetail')),
      },
    ],
  },
];
