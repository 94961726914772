import React from 'react';

export const EmailPagesConfig = [
  {
    auth: ['superadmin','admin','ContentCreator'],
    routes: [
      {
        path: '/Email/overview/:page',
        component: React.lazy(() => import('./Pages/Emailoverview')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','ContentCreator'],
    routes: [
      {
        path: '/Email/Detail/:id',
        component: React.lazy(() => import('./Pages/EmailDetail')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','ContentCreator'],
    routes: [
      {
        path: '/Email/Delete/:page/:deleteID',
        component: React.lazy(() => import('./Pages/Emailoverview')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','ContentCreator'],
    routes: [
      {
        path: '/Email/new/',
        component: React.lazy(() => import('./Pages/new')),
      },
    ],
  },
];
