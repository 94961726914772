export interface NavItemProps {
  id: string;
  messageId: string;
  title: string;
  icon?: string;
  exact?: boolean;
  url?: string;
  type?: string;
  count?: number;
  color?: string;
  auth?: string[];
  children?: NavItemProps[] | NavItemProps;
}

const routesConfig: NavItemProps[] = [
  {
    id: 'sample',
    title: `sidebar.content`,
    messageId: 'sidebar.content',
    type: 'collapse',
    icon: 'dashboard',
    auth: ['superadmin', 'admin', 'ContentCreator', 'Author'],
    children: [
      {
        id: 'Blog',
        title: `sidebar.content.single`,
        messageId: 'sidebar.content.single',
        type: 'item',
        icon: 'post_add',
        auth: ['superadmin', 'admin', 'ContentCreator', 'Author'],
        url: '/Blog/overview/1',
      },
      {
        id: 'Calender',
        title: `sidebar.content.calender`,
        messageId: 'sidebar.content.calender',
        type: 'item',
        icon: 'post_add',
        auth: ['superadmin', 'admin', 'ContentCreator', 'Author'],
        url: '/Calender/overview/1',
      },
      {
        id: 'Pages',
        title: `sidebar.content.post`,
        messageId: 'sidebar.content.post',
        type: 'item',
        icon: 'account_tree',
        auth: ['superadmin', 'admin', 'ContentCreator'],
        url: '/Pages/overview/1',
      },
      {
        id: 'email',
        title: `sidebar.content.email`,
        messageId: 'sidebar.content.email',
        type: 'item',
        icon: 'email',
        auth: ['superadmin', 'admin', 'ContentCreator'],
        url: '/Email/overview/1',
      },
      {
        id: 'Snippet',
        title: `sidebar.content.text`,
        messageId: 'sidebar.content.text',
        type: 'item',
        icon: 'article',
        auth: ['superadmin', 'admin', 'ContentCreator'],
        url: '/Snippet/overview/1',
      },
     {
             id: 'Textbausteine',
             title: 'Textbausteine',
             messageId: 'Text Bausteine',
             type: 'item',
             icon: 'query_stats',
             auth: ['superadmin', 'admin'],
             url: '/TextBlocks/overview/1',
           },
            {
                   id: 'customizer',
                   title: 'customizer',
                   messageId: 'Individueller CSS Style',
                   type: 'item',
                   icon: 'query_stats',
                   auth: ['superadmin', 'admin'],
                   url: '/customizer/overview/1',
                 },
    ],
  },
  {
    id: 'orders',
    title: `sidebar.orders`,
    messageId: 'sidebar.orders',
    type: 'collapse',
    icon: 'shopping_bag',
    auth: ['superadmin', 'admin', 'operator'],
    children: [
      {
        id: 'orders-show',
        title: 'sidebar.orders.overviewNew',
        messageId: 'sidebar.orders.overviewNew',
        type: 'item',
        icon: 'visibility',
        auth: ['superadmin', 'admin', 'operator'],
        url: '/Orders/overview/1',
      },
            {
              id: 'orders-show-promotion',
              title: 'Gutscheine',
              messageId: 'Gutscheine',
              type: 'item',
              icon: 'visibility',
              auth: ['superadmin', 'admin', 'operator'],
              url: '/Promotion/overview/1',
            },
    ],
  },
  {
    id: 'products',
    title: `sidebar.products`,
    messageId: 'sidebar.products',
    type: 'collapse',
    icon: 'list_alt',
    auth: ['superadmin', 'admin', 'operator'],
    children: [
      {
        id: 'products-show',
        title: 'sidebar.products.show',
        messageId: 'sidebar.products.show',
        type: 'item',
        icon: 'visibility',
        auth: ['superadmin', 'admin', 'operator'],
        url: '/Product/overview/1',
      },
    ],
  },
  {
    id: 'merchant',
    title: `sidebar.merchants`,
    messageId: 'sidebar.merchants',
    type: 'collapse',
    icon: 'people',
    auth: ['superadmin', 'admin', 'operator'],
    children: [
      {
        id: 'merchant-show',
        title: 'sidebar.merchants.show',
        messageId: 'sidebar.merchants.show',
        type: 'item',
        icon: 'visibility',
        auth: ['superadmin', 'admin', 'operator'],
        url: '/Merchant/overview/1',
      },
    ],
  },
  {
    id: 'bill',
    title: 'sidebar.bill',
    messageId: `sidebar.bill`,
    type: 'collapse',
    icon: 'account_balance',
    auth: ['superadmin', 'admin', 'operator'],
    children: [
      {
        id: 'bill-show',
        title: `sidebar.bill.show`,
        messageId: 'sidebar.bill.show',
        type: 'item',
        icon: 'payments',
        auth: ['superadmin', 'admin', 'operator'],
        url: '/Invoice/overview/1',
      },
    ],
  },
    {
      id: 'matomo',
      title: 'matomo.menu.header',
      messageId: `matomo.menu.header`,
      type: 'collapse',
      icon: 'stacked_line_chart',
      auth: ['superadmin', 'admin', 'operator'],
      children: [
        {
          id: 'dashboard',
          title: `matomo.menu.item.dashboard`,
          messageId: 'matomo.menu.item.dashboard',
          type: 'item',
          icon: 'equalizer',
          auth: ['superadmin', 'admin', 'operator'],
          url: '/analytics/Dashboard/0',
        },
        {
          id: 'visitor',
          title: `matomo.menu.item.visitor`,
          messageId: 'matomo.menu.item.visitor',
          type: 'item',
          icon: 'visibility',
          auth: ['superadmin', 'admin', 'operator'],
          url: '/analytics/visitor/0',
        },
        {
          id: 'behave',
          title: `matomo.menu.item.behave`,
          messageId: 'matomo.menu.item.behave',
          type: 'item',
          icon: 'psychology',
          auth: ['superadmin', 'admin', 'operator'],
          url: '/analytics/behave/0',
        },
        {
          id: 'acquisition',
          title: `matomo.menu.item.acquisition`,
          messageId: 'matomo.menu.item.acquisition',
          type: 'item',
          icon: 'report',
          auth: ['superadmin', 'admin', 'operator'],
          url: '/analytics/acquisition/0',
        },
        {
          id: 'targets',
          title: `matomo.menu.item.targets`,
          messageId: 'matomo.menu.item.targets',
          type: 'item',
          icon: 'track_changes',
          auth: ['superadmin', 'admin', 'operator'],
          url: '/analytics/targets/0',
        },
      ],
    },
  {
    id: 'sidebar.settings.show.monitoring',
    title: `sidebar.settings.show.monitoring`,
    messageId: 'sidebar.settings.show.monitoring',
    type: 'collapse',
    icon: 'legend_toggle',
    auth: ['superadmin', 'admin', 'operator'],
    children: [
      {
        id: 'sidebar.settings.show.monitoring.system.overview',
        title: 'sidebar.settings.show.monitoring.system.overview',
        messageId: 'sidebar.settings.show.monitoring.system.overview',
        type: 'item',
        icon: 'query_stats',
        auth: ['superadmin', 'admin'],
        url: '/Monitoring/overview/1',
      },
      {
      id: 'sidebar.settings.show.monitoring.system.api',
      title: 'sidebar.settings.show.monitoring.system.api',
      messageId: 'sidebar.settings.show.monitoring.system.api',
      type: 'item',
      icon: 'query_stats',
      auth: ['superadmin', 'admin'],
      url: '/Monitoring/API/overview/1',
    },{
      id: 'sidebar.settings.show.monitoring.system.email',
      title: 'sidebar.settings.show.monitoring.system.email',
      messageId: 'sidebar.settings.show.monitoring.system.email',
      type: 'item',
      icon: 'query_stats',
      auth: ['superadmin', 'admin'],
      url: '/Monitoring/email/overview/1',
    },]
  },
  {
    id: 'sidebar.support.it',
    title: `sidebar.support.it`,
    messageId: 'sidebar.support.it',
    type: 'collapse',
    icon: 'support',
    auth: ['superadmin', 'admin', 'operator'],
    children: [
      {
        id: 'sidebar.support.it.ticketsystem',
        title: 'sidebar.support.it.ticketsystem',
        messageId: 'sidebar.support.it.ticketsystem',
        type: 'item',
        icon: 'bug_report',
        auth: ['superadmin', 'admin'],
        url: '/ticket/overview/1',
      },
      {
        id: 'sidebar.support.it.ticketsystem.label',
        title: 'sidebar.support.it.ticketsystem.label',
        messageId: 'sidebar.support.it.ticketsystem.label',
        type: 'item',
        icon: 'bug_report',
        auth: ['superadmin', 'admin'],
        url: '/ticket/Label/overview',
      },]
  },
  {
    id: 'settings',
    title: `sidebar.settings`,
    messageId: 'sidebar.settings',
    type: 'collapse',
    icon: 'settings',
    auth: ['superadmin', 'admin', 'operator'],
    children: [
      {
        id: 'settings-show-data',
        title: `sidebar.settings.show.data`,
        messageId: 'sidebar.settings.show.data',
        type: 'item',
        icon: 'edit',
        auth: ['superadmin', 'admin', 'operator'],
        url: '/Settings/data',
      },
      {
        id: 'settings-show-preheader',
        title: `sidebar.settings.show.preheader`,
        messageId: 'sidebar.settings.show.preheader',
        type: 'item',
        icon: 'edit',
        auth: ['superadmin', 'admin'],
        url: '/Settings/preheader',
      },
      {
        id: 'settings-show-taxon',
        title: `sidebar.settings.show.taxon`,
        messageId: 'sidebar.settings.show.taxon',
        type: 'item',
        icon: 'edit',
        auth: ['superadmin', 'admin', 'operator'],
        url: '/Taxon/overview/1',
      },
      {
        id: 'settings-show-tax',
        title: `sidebar.settings.show.preheader`,
        messageId: 'sidebar.settings.show.tax',
        type: 'item',
        icon: 'edit',
        auth: ['superadmin', 'admin', 'operator'],
        url: '/Tax/overview/1',
      },
      {
        id: 'settings-show-Shipping',
        title: 'sidebar.settings.show.shipping',
        messageId: 'sidebar.settings.show.shipping',
        type: 'item',
        icon: 'edit',
        auth: ['superadmin', 'admin', 'operator'],
        url: '/Shipping/overview/1',
      },
      {
        id: 'settings-show-unit',
        title: 'sidebar.settings.show.unit',
        messageId: 'sidebar.settings.show.unit',
        type: 'item',
        icon: 'edit',
        auth: ['superadmin', 'admin', 'operator'],
        url: '/Unit/overview/1',
      },
      {
        id: 'settings-show-member',
        title: 'sidebar.settings.show.member',
        messageId: 'sidebar.settings.show.member',
        type: 'item',
        icon: 'edit',
        auth: ['superadmin', 'admin'],
        url: '/Member/overview/1',
      },
      {
              id: 'Kundenverwaltung',
              title: 'Kundenverwaltung',
              messageId: 'Kundenverwaltung',
              type: 'item',
              icon: 'edit',
              auth: ['superadmin', 'admin'],
              url: '/Customer/overview/1',
            },
                  {
                    id: 'Config',
                    title: 'Config',
                    messageId: 'Template Settings',
                    type: 'item',
                    icon: 'query_stats',
                    auth: ['superadmin', 'admin'],
                    url: '/Config/overview/1',
                  },
    ],
  },
  {
    id: 'merchant-dashboard',
    title: 'sidebar.daschboard.merchant',
    messageId: 'sidebar.daschboard.merchant',
    type: 'item',
    icon: 'shopping_bag',
    auth: ['merchant', 'merchant_ma'],
    url: '/merchant/Dashboard',

  },
  {
    id: 'orders-merchant',
    title: 'sidebar.orders.merchant',
    messageId: 'sidebar.orders.merchant',
    type: 'item',
    icon: 'shopping_bag',
    auth: ['merchant', 'merchant_ma'],
    url: '/Orders/Merchant/overview/1',

  },
  {
    id: 'procduct-merchants',
    title: 'sidebar.products.merchant',
    messageId: 'sidebar.products.merchant',
    type: 'item',
    icon: 'shopping_bag',
    auth: ['merchant', 'merchant_ma'],
    url: '/Product/overview/1',

  },
  {
    id: 'profil-merchant',
    title: 'sidebar.profil.merchant',
    messageId: 'sidebar.profil.merchant',
    type: 'item',
    icon: 'shopping_bag',
    auth: ['merchant', 'merchant_ma'],
    url: '/merchant/Detail/0',

  },
  {
    id: 'settings-merchant',
    title: 'sidebar.settings.merchant',
    messageId: 'sidebar.settings.merchant',
    type: 'item',
    icon: 'shopping_bag',
    auth: ['merchant', 'merchant_ma'],
    url: '/SettingsMerchant/data',

  },
  {
    id: 'settings-show-meerchant-member',
    title: 'sidebar.settings.show.member',
    messageId: 'sidebar.settings.show.member',
    type: 'item',
    icon: 'edit',
    auth: ['merchant'],
    url: '/Member/Merchant/overview/1',
  },
/*  {
    id: 'settings-show-meerchant-member',
    title: 'Ticketverwaltung',
    messageId: 'Ticketverwaltung',
    type: 'item',
    icon: 'edit',
    auth: ['superadmin','admin','merchant','merchant_ma'],
    url: '/ticket/overview/1',
  },*/
];
export default routesConfig;
