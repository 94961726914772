import {AuthUser} from '../../types/models/AuthUser';
import {AuthType} from './AppEnums';

export const authRole = {
  admin: ['user','admin'],
  superadmin: ['user', 'superadmin'],
  Author:['user', 'Author'],
  ContentCreator:['user', 'ContentCreator'],
  operator:['user', 'operator'],
  merchant:['user', 'merchant'],
  merchant_ma:['user', 'merchant_ma'],
};
//SigninJwtAuth.tsx Z: 124 && AppHooks.tsx Z:28
export const defaultUser: AuthUser = {
  uid: 'RFedvhji876rfhjuecvh7',
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: authRole.superadmin,
  authType: AuthType.AUTH0,
  merchantID: '836',
  is_superuser: 0,
  photoURL: '/assets/images/avatar/A11.jpg',
};

export const initialUrl = '/'; // this url will open after login

export const initialUrlChannel = '/Preload/'; // this url will open after login

export const initialMerchantUrl = '/merchant/Dashboard'; // this url will open after login

export const initialNotificationUrl = '/notification/overview'; // this url will open after login

export const errorPageUrl = '/error-pages/error-404';

export const chatPagesUrl = '/chat/messenger';

export const PagePagesUrl = '/Pages/overview';

export const PageDetailPagesUrl = '/Pages/Detail/:id';

export const PageNewPagesUrl = '/Pages/new/';

export const PageDeletePagesUrl = '/Pages/Delete/:page/:deleteID';

export const MerchantPagesUrl = '/merchant/overview/:page';

export const MerchantDetailPagesUrl = '/merchant/Detail/:id';

export const SnippetPagesUrl = '/Snippet/overview';

export const SnippetDetailPagesUrl = '/Snippet/Detail/:id';

export const SnippetDeletePagesUrl = '/Snippet/Delete/:page/:deleteID';

export const SnippetNewPagesUrl = '/Snippet/new/';

export const BlogPagesUrl = '/Blog/overview/:page';

export const BlogDetailPagesUrl = '/Blog/Detail/:id';

export const BlogNewPagesUrl = '/Blog/new/';

export const BlogDeletePagesUrl = '/Blog/Delete/:page/:deleteID';

export const ProductPagesUrl = '/Product/overview/:page';

export const ProductDetailPagesUrl = '/Product/Detail/:id';

export const ProductNewPagesUrl = '/Product/new/';

export const EmailPagesUrl = '/Email/overview/:page';

export const EmailDetailPagesUrl = '/Email/Delete/:page/:deleteID';

export const EmailNewPagesUrl = '/Email/new/';

export const OrdersPagesUrl = '/Orders/overview/:page';

export const OrdersDetailPagesUrl = '/Orders/Detail/:id';

export const SettingsDataPagesUrl = '/Settings/data';

export const SettingsPreHeaderPagesUrl = '/Settings/preheader';

export const TaxonPagesUrl = '/Taxon/overview/:page';

export const TaxPagesUrl = '/Tax/overview/:page';

export const MemberPagesUrl = '/Member/overview/:page';

export const SettingsDataMerchantPagesUrl = '/SettingsMerchant/data';

export const MemberMerchantPagesUrl = '/Member/Merchant/overview/:page';

export const ShippingPagesUrl = '/Shipping/overview/:page';

export const MonitoringPagesUrl = '/Monitoring/overview/:page';

export const InvoicePagesUrl = '/Invoice/overview/:page';

export const TicketPagesUrl = '/ticket/overview/:page';

export const CalenderPagesUrl = '/calender/overview/:page';

export const TextBlocksPagesUrl = '/TextBlocks/overview/:page';

export const CustomerPagesUrl = '/Customer/overview/:page';

export const PromotionPagesUrl = '/Promotion/overview/:page';

export const PreloadPagesUrl = '/Preload/';

export const MatomoPagesUrl = '/analytics';

export const customizerPagesUrl = '/customizer/overview/:page';

export const ConfigPagesUrl = '/Config/overview/:page';
