import React from 'react';



export const BlogPagesConfig = [
  {
    auth: ['superadmin','admin','ContentCreator','Author'],
    routes: [
      {
        path: '/Blog/overview/:page',
        component: React.lazy(() => import('./Pages/overview')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','ContentCreator','Author'],
    routes: [
      {
        path: '/Blog/Detail/:id',
        component: React.lazy(() => import('./Pages/Detail')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','ContentCreator','Author'],
    routes: [
      {
        path: '/Blog/Delete/:page/:deleteID',
        component: React.lazy(() => import('./Pages/overview')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','ContentCreator','Author'],
    routes: [
      {
        path: '/Blog/new/',
        component: React.lazy(() => import('./Pages/new')),
      },
    ],
  },
];
