import React from 'react';

export const TextBlocksPagesConfig = [
  {
    auth: ['superadmin'],
    routes: [
      {
        path: '/TextBlocks/overview/:page',
        component: React.lazy(() => import('./Pages/overview')),
      },
    ],
  },
  {
    auth: ['superadmin'],
    routes: [
      {
        path: '/TextBlocks/Detail/:id',
        component: React.lazy(() => import('./Pages/Detail')),
      },
    ],
  },
  {
    auth: ['superadmin'],
    routes: [
      {
        path: '/TextBlocks/Delete/:page/:deleteID',
        component: React.lazy(() => import('./Pages/overview')),
      },
    ],
  },
  {
    auth: ['superadmin'],
    routes: [
      {
        path: '/TextBlocks/new/',
        component: React.lazy(() => import('./Pages/new')),
      },
    ],
  },
];
