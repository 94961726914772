import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import PielersForm from "../../form-serilizer";
import React from "react";


const MediaGalerie = () => {

    const closeModal = (id:any,idsecond:any) => {
        let eles = document.getElementById(id) as HTMLDivElement;
        eles.style.display = 'none';
        let ele = document.getElementById(idsecond) as HTMLDivElement;
        ele.innerHTML = '';
        let eletags = document.getElementById('tags') as HTMLDivElement;
        eletags.innerHTML = '';
    }

    const closeModalIMG = (id:any,idsecond:any) => {
        let eles = document.getElementById(id) as HTMLDivElement;
        eles.style.display = 'none';
        let eless = document.getElementById(idsecond) as HTMLDivElement;
        eless.innerHTML = '';
        let date = document.getElementById('ga-img-date') as HTMLSpanElement;
        date.innerHTML =  'Loading....';

        let name = document.getElementById('ga-img-name') as HTMLSpanElement;
        name.innerHTML =  'Loading....';

        let content_type = document.getElementById('ga-img-type') as HTMLSpanElement;
        content_type.innerHTML =  'Loading....';

        let content_size = document.getElementById('ga-img-content_size') as HTMLSpanElement;
        content_size.innerHTML =  'Loading....';

        let width = document.getElementById('ga-img-width') as HTMLSpanElement;
        width.innerHTML =  'Loading....';

        let height = document.getElementById('ga-img-height') as HTMLSpanElement;
        height.innerHTML =  'Loading....';

        let eleIMG = document.getElementById('placeIMG') as HTMLImageElement;
        eleIMG.src = '';
    }
    const config =
        [{
            'formType': 'formFileBucket',
            'onclick': true,
            'onclick_context': 'pielers_element_text_image',
            'append': 'preview_media',
            'experiment':true,
            'src': 'image_title_image_',
            'type': 'file',
            'size': {
                'xl': 10,
                'md': 10,
                'sm': 10,
            }
        }]
    let logo;
    if (process.env.REACT_APP_CHANNEL === 'KLIMABAUHELD') {
        logo = process.env.REACT_APP_CHANNEL_KLIMABAUHELD_LOGO_LOGIN;
    } else if (process.env.REACT_APP_CHANNEL === 'MYHOMICE') {
        logo = process.env.REACT_APP_CHANNEL_MYHOMICE_LOGO_LOGIN;
    }
    return (<>

        <div id={'modal'}>
            <div id={'dialog'} role="dialog" aria-modal="true" className="fade modal show">
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content" style={{paddingTop: 0, paddingBottom: 0}}>
                        <div className="modal-header">
                            <img src={'/assets/images/'+logo} width={160} height={60} alt="Logo"
                                 className="SidebarLogo" style={{marginRight:'15px'}}/>
                            <div className="modal-title h4 ml-4">Bildergalerie</div>
                            <button type="button" className="btn-close" aria-label="Close"
                                    onClick={() => closeModal('dialog','imgHolder')}/>
                        </div>
                        <div className="modal-body">
                            <Tabs key={'home'} defaultActiveKey='content' id='uncontrolled-tab-example' className='mb-3' >
                                <Tab eventKey='home' title={'Datei Upload'} key={'allgemein'} id={'allgemein'}
                                     style={{backgroundColor: 'White', padding: '15px', marginTop: '-15px'}}>
                                    <input id={'namespace'} type={'hidden'} defaultValue={''} />
                                    <input id={'Imgsrc'} type={'hidden'} defaultValue={''} />
                                    <input id={'append'} type={'hidden'} defaultValue={''} />
                                    <input id={'Galeriemode'} type={'hidden'} defaultValue={''} />
                                    {PielersForm(config)}
                                </Tab>
                                <Tab key={'content'} eventKey='content' title={'Bildergalerie'} id={'Inhalt'}
                                     style={{backgroundColor: 'White', padding: '15px', marginTop: '-15px'}}>
                                    <div id={'tags'} />
                                    <br />
                                    <div className="row ga-h-100">
                                        <div className="col ga-h-100">
                                            <div id="imgHolder" className="row ga-img-holder"/>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="float-end btn btn-primary"
                                    onClick={() => closeModal('dialog','imgHolder')}>Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id={'modal'}>
            <div id={'ga-options'} role="dialog" aria-modal="true" className="fade modal show">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content" style={{paddingTop: 0, paddingBottom: 0}}>
                        <div className="modal-header">
                            <img src={process.env.REACT_APP_LOGO_IMG} height="60" alt="Logo"
                                 className="SidebarLogo"/>
                            <div className="modal-title h4 ml-4">Anhang-Details</div>
                            <button type="button" className="btn-close" aria-label="Close"
                                    onClick={() => closeModalIMG('ga-options','optionHolder')}/>
                        </div>
                        <div className="modal-body" style={{padding: '0px 12px 12px  12px'}}>
                            <div className="row">
                                <div className="details" style={{background: '#f6f7f7'}}>

                                    <br />
                                    <div className="uploaded"><strong>Hochgeladen am: </strong> <span id={'ga-img-date'}>Loading....</span> |

                                        <strong> Dateiname: </strong> <span id={'ga-img-name'}>Loading....</span>
                                    </div>
                                    <div className="file-type"><strong> Dateityp: </strong><span id={'ga-img-type'}>Loading....</span> |
                                        <strong> Dateigröße: </strong><span id={'ga-img-content_size'}>Loading....</span> kB | <strong>Abmessungen: </strong>
                                        <span id={'ga-img-width'}>Loading....</span> auf <span id={'ga-img-height'}>Loading....</span> Pixel
                                    </div>

                                    <br />

                                </div>
                            </div>
                            <div className="row ga-h-100">
                                <div id="imgPlace" className="col">
                                    <br />
                                    <img id={'placeIMG'}  alt={''}/>
                                </div>
                                <div id="optionHolder" className="col ga-options" />
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="float-end btn btn-danger"
                                    onClick={() => closeModalIMG('ga-options','optionHolder')}>Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default MediaGalerie;
