import React from 'react';

export const TaxPagesConfig = [
  {
    auth: ['superadmin','admin','operator'],
    routes: [
      {
        path: '/Tax/overview/:page',
        component: React.lazy(() => import('./Pages/overview')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','operator'],
    routes: [
      {
        path: '/Tax/Detail/:id',
        component: React.lazy(() => import('./Pages/Detail')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','operator'],
    routes: [
      {
        path: '/Tax/Delete/:page/:deleteID',
        component: React.lazy(() => import('./Pages/overview')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','operator'],
    routes: [
      {
        path: '/Tax/new/',
        component: React.lazy(() => import('./Pages/new')),
      },
    ],
  },
];
