import React from 'react';
function divider() {

    return (
      <>
        <br />
      </>
    );

}

export default divider;
