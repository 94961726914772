import React from 'react';

export const TaxonPagesConfig = [
  {
    auth: ['superadmin','admin','operator','haendler','hersteller','handwerker'],
    routes: [
      {
        path: '/Taxon/overview/:page',
        component: React.lazy(() => import('./Pages/overview')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','operator','haendler','hersteller','handwerker'],
    routes: [
      {
        path: '/Taxon/Detail/:id',
        component: React.lazy(() => import('./Pages/Detail')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','operator'],
    routes: [
      {
        path: '/Taxon/Delete/:page/:deleteID',
        component: React.lazy(() => import('./Pages/overview')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','operator'],
    routes: [
      {
        path: '/Taxon/new/',
        component: React.lazy(() => import('./Pages/new')),
      },
    ],
  },
];
