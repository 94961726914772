import React from 'react';

export const InvoicePagesConfig = [
  {
    auth: ['superadmin','admin'],
    routes: [
      {
        path: '/Invoice/overview/:page',
        component: React.lazy(() => import('./Pages/overview')),
      },
    ],
  },
  {
    auth: ['superadmin','admin'],
    routes: [
      {
        path: '/Invoice/Detail/:id',
        component: React.lazy(() => import('./Pages/Detail')),
      },
    ],
  },
];
