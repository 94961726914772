import Button from 'react-bootstrap/Button';
import React from 'react';

const deleteTaxonButton = (id:any,className:any = false,type:any=false) => {
  let setClass = '';
  if(className !== false) {
  setClass = className;
}

    return(<>
      <Button className={setClass} type={'button'} onClick={() => deleteElement(id)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash"
             viewBox="0 0 16 16">
          <path
            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
          <path fillRule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
        </svg>
      </Button>

    </>)




}
export default deleteTaxonButton;


const deleteElement = (id: any, onHide:any =false) => {

  // Identifizieren des Kindknotens
  var element = document.getElementById(id);

// Aufruf des Elternknotens, um dann dessen Kindknoten zu löschen
  element?.parentNode?.removeChild(element);
  if(onHide !== false){
    onHide();
  }

  var elementInput = document.getElementsByClassName('taxon');

  let iterator = 0;
  for(let ele=0;ele < elementInput.length;ele++){
    let setter = (iterator + 1) * 3 - 1;
    let setter2 = (iterator + 1) * 3 - 2;
    let setter3 = (iterator + 1) * 3 - 3;
    if(ele === setter3) {
      elementInput[ele].setAttribute('name', 'taxons['+iterator+'][id]');
    }

    if(ele === setter2) {
      elementInput[ele].setAttribute('name', 'taxons['+iterator+'][name]');
    }

    if(ele === setter) {
      elementInput[ele].setAttribute('name', 'taxons['+iterator+'][order]');
      iterator++;
    }

  }
  //console.log(document.getElementsByClassName('taxon'));
}
