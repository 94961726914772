import React from 'react';

export const PagePagesConfig = [
  {
    auth: ['superadmin','admin','ContentCreator'],
    routes: [
      {
        path: '/Pages/overview/:page',
        component: React.lazy(() => import('./Pages/Pageoverview')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','ContentCreator'],
    routes: [
      {
        path: '/Pages/Detail/:id',
        component: React.lazy(() => import('./Pages/PageDetail')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','ContentCreator'],
    routes: [
      {
        path: '/Pages/Delete/:page/:deleteID',
        component: React.lazy(() => import('./Pages/Pageoverview')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','ContentCreator'],
    routes: [
      {
        path: '/Pages/new/',
        component: React.lazy(() => import('./Pages/new')),
      },
    ],
  },
];
