import React from 'react';

export const MatomoPagesConfig = [
        {
            auth: ['superadmin','admin'],
            routes: [
                {
                    path: '/analytics/Dashboard/:page',
                    component: React.lazy(() => import('./Pages/anayltics')),
                },
            ],
        },
        {
            auth: ['superadmin','admin'],
            routes: [
                {
                    path: '/analytics/visitor/:page',
                    component: React.lazy(() => import('./Pages/visitor')),
                },
            ],
        },
        {
            auth: ['superadmin','admin'],
            routes: [
                {
                    path: '/analytics/behave/:page',
                    component: React.lazy(() => import('./Pages/behave')),
                },
            ],
        },
        {
            auth: ['superadmin','admin'],
            routes: [
                {
                    path: '/analytics/acquisition/:page',
                    component: React.lazy(() => import('./Pages/acquisition')),
                },
            ],
        },
        {
            auth: ['superadmin','admin'],
            routes: [
                {
                    path: '/analytics/targets/:page',
                    component: React.lazy(() => import('./Pages/targets')),
                },
            ],
        },
];


