import React from 'react';

import Button from 'react-bootstrap/Button'

function header(data: any) {

  let variant = data.variants
  let label = data.label
  let value = data.value

  let target = (data.target !== undefined ? data.target : '_blank')

  return (
    <>
      <><br /><Button variant={variant} as={'a'} href={value} target={target}>{label}</Button></>
    </>
  );
}

export default header;