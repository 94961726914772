import React from 'react';

export const PreloaderPagesConfig = [
  {
    auth: ['superadmin','admin','operator','merchant','merchant_ma'],
    routes: [
      {
        path: '/Preload/',
        component: React.lazy(() => import('./Pages/PreloadPielers')),
      },
    ],
  },
];
