import React from 'react';

export const UnitPagesConfig = [
  {
    auth: ['superadmin','admin','operator'],
    routes: [
      {
        path: '/Unit/overview/:page',
        component: React.lazy(() => import('./Pages/overview')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','operator'],
    routes: [
      {
        path: '/Unit/Detail/:id',
        component: React.lazy(() => import('./Pages/Detail')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','operator'],
    routes: [
      {
        path: '/Unit/Delete/:page/:deleteID',
        component: React.lazy(() => import('./Pages/overview')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','operator'],
    routes: [
      {
        path: '/Unit/new/',
        component: React.lazy(() => import('./Pages/new')),
      },
    ],
  },
];
