import React from 'react';

function description(data: any) {

  return (
    <>
      <><br /><div dangerouslySetInnerHTML={ {__html: data.value} } /></>
    </>
  );
}

export default description;