import React from 'react';
import Col from 'react-bootstrap/Col';
import renderFormSwitch from '../../registerForm';

function col_helper(data:any,) {
  const row = [];

  for (let c = 0; c < data.cols.length; c++) {

    if(data.cols[c].size !== undefined) {
      row.push(<><Col xl={data.cols[c].size.xl} md={data.cols[c].size.xl} sm={data.cols[c].size.xl}>{renderFormSwitch(data.cols,c)}</Col></>);
    } else {
      row.push(<><Col md className={data.class}>{renderFormSwitch(data.cols,c)}</Col></>);
    }

  }
  if(data.hasOwnProperty('cols')) {
    return (
      <>
        <>{row}</>
      </>
    )
  } else {
    return;
  }
}

export default col_helper;