import React from 'react';

export const SettingsPagesConfig = [
  {
    auth: ['superadmin','admin','operator'],
    routes: [
      {
        path: '/Settings/data',
        component: React.lazy(() => import('./Pages/SettingsData')),
      },
    ],
  },
  {
    auth: ['superadmin','admin'],
    routes: [
      {
        path: '/Settings/preheader',
        component: React.lazy(() => import('./Pages/SettingsPreHeader')),
      },
    ],
  },
];
