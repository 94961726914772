import React from 'react';

export const customizerPagesConfig = [
  {
    auth: ['superadmin','admin','ContentCreator'],
    routes: [
      {
        path: '/customizer/overview/:page',
        component: React.lazy(() => import('./Pages/overview')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','ContentCreator'],
    routes: [
      {
        path: '/customizer/Detail/:id',
        component: React.lazy(() => import('./Pages/Detail')),
      },
    ],
  },

];

