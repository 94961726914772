const replaceHTMLTag = (data:any) => {

  var htmlReplace = data;
  for(let x = 0; x < mapSearchAndReplace().length;x++){
  htmlReplace =  htmlReplace.replaceAll(mapSearchAndReplace()[x].search,mapSearchAndReplace()[x].replace);
  }

  return htmlReplace.replaceAll(/&lt;/g,'<').replaceAll(/&gt;/g,'>').replaceAll(/&amp;/g,'&');

}

export default replaceHTMLTag;

const mapSearchAndReplace = () => {

  const map = [
    {search: "[bold]",replace:"<strong>"},
    {search: "[/bold]",replace:"</strong>"}
  ]
  return map;

}