import React from 'react';

export const ProductPagesConfig = [
  {
    auth: ['superadmin','admin','operator','merchant','merchant_ma','haendler','hersteller'],
    routes: [
      {
        path: '/Product/overview/:page',
        component: React.lazy(() => import('./Pages/Productoverview')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','operator','merchant','merchant_ma','haendler','hersteller'],
    routes: [
      {
        path: '/Product/Detail/:id',
        component: React.lazy(() => import('./Pages/ProductDetail')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','operator','merchant','merchant_ma','haendler','hersteller'],
    routes: [
      {
        path: '/Product/Show/:id',
        component: React.lazy(() => import('./Pages/ProductDetail')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','operator','merchant','merchant_ma','haendler','hersteller'],
    routes: [
      {
        path: '/Product/Delete/:id',
        component: React.lazy(() => import('./Pages/ProductDetail')),
      },
    ],
  },
  {
    auth: ['superadmin','admin','operator','merchant','merchant_ma','haendler','hersteller'],
    routes: [
      {
        path: '/Product/new/',
        component: React.lazy(() => import('./Pages/new')),
      },
    ],
  },
];
