import React from 'react';

export const chatPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/chat/messenger',
        component: React.lazy(() => import('./Pages/chat')),
      },
    ],
  }
];