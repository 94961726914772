import saMessages from '../locales/de_DE.json';
import {deDE} from '@material-ui/core/locale';

const deLang = {
  messages: {
    ...saMessages,
  },
  muiLocale: deDE,
  locale: 'de',
};
export default deLang;
