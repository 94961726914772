export interface LanguageProps {
  languageId: string;
  locale: string;
  name: string;
  icon: string;
}

const languageData: LanguageProps[] = [
 {
    languageId: 'deutsch',
    locale: 'de',
    name: 'Deutsch',
    icon: 'de',
  },
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
];
export default languageData;
