import React from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

const AppLogoWhite = () => {

  let logo;
  if (process.env.REACT_APP_CHANNEL === 'KLIMABAUHELD') {
    logo = process.env.REACT_APP_CHANNEL_KLIMABAUHELD_LOGO_LOGIN;
  } else if (process.env.REACT_APP_MODE === 'MYHOMICE') {
    logo = process.env.REACT_APP_CHANNEL_MYHOMICE_LOGO_LOGIN;
  }

  const useStyles = makeStyles(() => ({
    logoRoot: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      alignItems: 'center',
    },
    logo: {
      height: 36,
      marginRight: 10,
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.logoRoot}>
      <Hidden smUp>
        <img
          className={classes.logo}
          src={'/assets/images/'+logo}
          alt='crema-logo'
        />
      </Hidden>
      <Hidden xsDown>
        <img
          className={classes.logo}
          src={'/assets/images/'+logo}
          alt='crema-logo'
        />
      </Hidden>
    </Box>
  );
};

export default AppLogoWhite;
