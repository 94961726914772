import React from 'react';
import Form from 'react-bootstrap/Form';
import legende from './helper/legende';

function radio(data: any) {

  let required = false;
  if(data.required !== undefined && data.required !== null && data.required !== false){
    required = true;
  }

if(data.id !== undefined) {

  return (
    <>
      <Form.Group controlId={"radio"+data.label} key={"radio"+data.label} className="mb-3">
        <Form.Check name={data.name} type={'radio'} value={data.value} label={data.label} id={data.id} aria-label="option 1" defaultChecked={data.visible === 1 || data.visible === '1'} required={required}/>
      </Form.Group>
      {legende(data)}
    </>
  );
} else {
  return (
    <>
      <Form.Group controlId={"radio"+data.label} key={"radio"+data.label} className="mb-3">
        <Form.Check name={data.name} type={'radio'} value={data.value} label={data.label} aria-label="option 1" defaultChecked={data.visible === 1 || data.visible === '1'} required={required}/>
      </Form.Group>
      {legende(data)}
    </>
  );
}
}

export default radio;


