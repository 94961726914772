import React, {ErrorInfo} from 'react';
import Box from '@material-ui/core/Box';
import {Button} from 'react-bootstrap';
import IntlMessage from '../../../@Pielers/components/const/IntlMessage';

class AppErrorBoundary extends React.Component<{}, {hasError: boolean}> {
  constructor(props: any) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.log('errorInfo: ', errorInfo);
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          display='flex'
          alignItems='center'
          flexDirection='column'
          justifyContent='center'
          flex={1}>
          <img src={'/assets/images/error.png'} alt='Error Info' />
          <Box component='h2' fontSize={30} mt={4}>
           <IntlMessage messageId={'AppErrorBoundary.text.title'} />
          </Box>
          <Box fontSize={18} textAlign='center'>
            <IntlMessage messageId={'AppErrorBoundary.text.description.first'} />
          </Box>
          <Box fontSize={18} textAlign='center'>
            <IntlMessage messageId={'AppErrorBoundary.text.description.second'} />
          </Box>
          <br />
          <Button type={'button'} variant={'success'} onClick={()=> window.location.reload()}><IntlMessage messageId={'AppErrorBoundary.button.text'} /></Button>
        </Box>
      );
    } else {
      return this.props.children;
    }
  }
}

export default AppErrorBoundary;
